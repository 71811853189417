import React, { useState, useEffect } from "react";
import '../drawer/SideNav.css';
import profileImage from '../../images/profile.jpg';
import { faCalendar, faComment, faLightbulb, faBell, faCog, faLifeRing, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link,useHistory } from 'react-router-dom';
import axios from 'axios';

const BASE_IMAGE_URL =
  "https://assets.layupcloud.com/uploads/playgroundqe/profile/";

const  SideNav = ({token ,userImage, userId,userName})=> {

  console.log(userName);
  console.log(userImage);
  var tokenx = sessionStorage.getItem('authToken');

  // const history = useHistory();

  const handleLogout = async () => {
    console.log("bearer token test ",tokenx);
    try {
      await axios.post(
        'https://api.layupdeveloper.com/playgroundqe/v1/logout?navigate=1',
        {},
        {
          headers: {
            Authorization: `Bearer ${tokenx}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      ); 
      sessionStorage.clear();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className="side-nav">
      <div className="profile">
      <img  src={`${BASE_IMAGE_URL}${userId}/${userImage}`} alt="Profile" />
        <div className="admin-tag">Admin</div>
        <h3>{userName}</h3>
        <p className="rank">Rank: Expert</p>
      </div>
      <div className="stats">
        <div className="stat-item">
          <i className="fas fa-book"></i>
          <p>Courses</p>
          <p>8</p>
        </div>
        <div className="stat-item">
          <i className="fas fa-award"></i>
          <p>Badges</p>
          <p>3</p>
        </div>
        <div className="stat-item">
          <i className="fas fa-certificate"></i>
          <p>Certificates</p>
          <p>2</p>
        </div>
      </div>
      <hr className='horizontal-line'/>
      <div className="points-earned">
        <p className="points-label">Points Earned:</p>
        <span className="points">64.8k</span>
      </div>
      <hr className='horizontal-line'/>
      <ul className="nav-items">
      <Link to="https://app.layupdeveloper.com/events.php?site=playgroundqe">
        <li><FontAwesomeIcon icon={faCalendar} /> <span className="icon-text">Events</span></li>
        </Link>
        <li className="active"><FontAwesomeIcon icon={faComment} /> <span className="icon-text">Discussion</span></li>
        <Link to="https://app.layupdeveloper.com/ideabox.php?site=playgroundqe">
        <li>  <FontAwesomeIcon icon={faLightbulb} /> <span className="icon-text">Ideabox</span></li>
          </Link>
          <Link to="https://app.layupdeveloper.com/ideabox.php?site=playgroundqe">
        <li><FontAwesomeIcon icon={faBell} /> <span className="icon-text">Announcements</span></li>
        </Link>
        <Link to="https://app.layupdeveloper.com/user_settings.php?site=playgroundqe">
        <li><FontAwesomeIcon icon={faCog} /> <span className="icon-text">User Settings</span></li>
        </Link>
        <Link to="https://layup.gitbook.io/help-center-for-users">
        <li><FontAwesomeIcon icon={faLifeRing} />  <span className="icon-text">Support</span></li>
        </Link>
        {/* <Link to="http://localhost/layuplive/index.php?logout=1&site=playgroundqe"> */}
        <li className='logout-button' onClick={handleLogout} ><FontAwesomeIcon icon={faSignOutAlt} /> <span className="icon-text">Logout</span></li>
        {/* </Link> */}
      </ul>
     
    </div>
  );
}

export default SideNav;
