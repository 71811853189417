import React, { useState } from 'react';
import './dropdown.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const Dropdown = ({ onFilterChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('All discussions');

  const options = [
    { label: 'All discussions', value: 1 },
    { label: 'My discussions', value: 2 },
    { label: 'Recent discussions', value: 3 },
    { label: 'Popular discussions', value: 4 },
    { label: 'Trending this week', value: 5 },
  ];

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option.label);
    setIsOpen(false);
    onFilterChange(option.value); 
  };

  return (
    <div className="dropdown">
      <div className='dropdown-section' onClick={toggleDropdown}>
        <h3 className="dropdown-header">
          {selectedOption}
        </h3>
        <FontAwesomeIcon icon={faCaretDown} className="dropdown-arrow" />
      </div>
      {isOpen && (
        <ul className="dropdown-menu">
          {options.map((option) => (
            <li
              key={option.value}
              className={`dropdown-item ${option.label === selectedOption ? 'selected' : ''}`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
              {option.label === selectedOption && (
                <FontAwesomeIcon icon={faCheck} className="check-icon" />
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
