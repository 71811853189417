import React, { useState } from 'react';
import '../defaultDiscussion/defaultDiscussion.css';
import messageIcon from '../../images/message-icon.png';
import AddDiscussion from '../addDiscussion/addDiscussion';

const NoDiscussions = ({onAddDiscussionClick,isAddDiscussionOpen }) => {

 
  return (
    <div className="no-discussions-container">
      <div className="no-discussions-icon">
        <img src={messageIcon} alt="No discussions" />
      </div>
      <h3 className="no-discussions-title">Discussions</h3>
      <p className="no-discussions-message">
        You haven’t opened any discussion yet, please select a message
      </p>
      <div className="no-discussions-divider">
        <span className="line"></span>
        <span className="or">or</span>
        <span className="line"></span>
      </div>
      <button className="new-discussion-button"  onClick={onAddDiscussionClick}>
        <i className="fas fa-plus" ></i> Add a New Discussion
      </button>
    </div>
  );
};

export default NoDiscussions;
