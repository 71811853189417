import React from 'react';
import '../discussionItem/discussionItem.css';
import userImage from '../../images/profile.jpg';
import Avatar from 'react-avatar'; 
import ProfileImage from "../avatarImage/avatarImage";
import moment from 'moment';
import { LuDot } from "react-icons/lu";


const DiscussionItem = ({ name, time, comments, topic,user_image,onClick  }) => {
    
    const isImageUrl = (url) => {
        const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp)$/i;
        return imageExtensions.test(url);
    };

    const formatTime = (time) => {
        let formattedTime;
        try {
            formattedTime = moment(time, 'M/D/YYYY, h:mm:ss A').fromNow();
        } catch (error) {
            console.error('Invalid time value:', time);
            return 'Invalid time';
        }
        return formattedTime || 'Invalid time';
    };

    return (
        <li className="discussion-item" onClick={onClick}>
        
            <div className="discussion-avatar">

            {user_image && isImageUrl(user_image) ? (
                    <img
                        className="user-image"
                        src={user_image}
                        
                    />
                ) : (
                    <ProfileImage name={name} />
                )}
            
                {/* <img className='user-image' src={user_image} /> */}
            </div>
            <div className="discussion-info">
            <span className="discussion-name">{name}</span>
                <div className="discussion-header">
                   
                    <span className="discussion-time">{formatTime(time)}</span>
                    <div className="discussion-comments"> <LuDot /> {comments} Comments</div>
                </div>
                <div className="discussion-topic">{topic}</div>
               
                
            </div>
        </li>
    );
};

export default DiscussionItem;
