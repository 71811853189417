import React from 'react';
import PropTypes from 'prop-types';
import '../profilePicture/profilePicture.css'; 

const ProfileImage = ({ imageUrl, name }) => {

    const isImageUrl = (imageUrl) => {
        const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp)$/i;
        return imageExtensions.test(imageUrl);
    };

  return (
    <div className="profile-image-container">
      {imageUrl && isImageUrl(imageUrl) ? (
        <img
          className="profile-image"
          src={imageUrl}
          alt="Profile"
        />
      ) : (
        <div className="user-profile-image">
          {name ? name[0] : '?'}
        </div>
      )}
    </div>
  );
};

ProfileImage.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default ProfileImage;
