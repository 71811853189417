import React, { useState, useEffect, useCallback } from "react";
import "../comments/comments.css";
import profileImage from "../../images/profile.jpg";
import "@fortawesome/fontawesome-free/css/all.min.css";
import commentGif from "../../images/thinking-emoji.gif";
import axios from "axios";
import ProfileImage from "../profilePicture/profilePicture";
import moment from "moment";

const BASE_IMAGE_URL =
  "https://assets.layupcloud.com/uploads/playgroundqe/profile/";

const CommentSection = ({ discussion , token,userImage,userId}) => {
  const [discussionComments, setDiscussionComments] = useState({
    commentsCount: 0,
    commentList: [],
  });

  const [newComment, setNewComment] = useState("");
  const [error, setError] = useState("");
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);


const handleFileChange = (event) => {
  const selectedFiles = Array.from(event.target.files);
  setFiles(selectedFiles);
  setFileNames(selectedFiles.map(file => file.name));
  console.log(fileNames)
};

const handleFileInputClick = () => {
  document.getElementById('file-input').click();
};
 
    const fetchDiscussionComments = useCallback(async () => {
      try {
        const response = await axios.get(
          `https://api.layupdeveloper.com/playgroundqe/v1/single-discussion-comments?discussion_id=${discussion.id}`,
          {
            headers: {
              Authorization:
              `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        setDiscussionComments(response.data.result.comments);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    }, [discussion.id]);

    useEffect(() => {
      fetchDiscussionComments(); 
  
      const intervalId = setInterval(() => {
        fetchDiscussionComments();
      }, 2000); 
  
      return () => clearInterval(intervalId); 
    }, [fetchDiscussionComments]);

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleAddComment = async () => {
    if (newComment.trim() === "") {
      setError("Comment cannot be empty.");
      return;
    }
    

    try {
      console.log(discussion.id);
      console.log(newComment);

      // const formData = new FormData();
      // formData.append("discussion_id", discussion.id);
      // formData.append("description", newComment);
      // files.forEach((file, index) => {
      //   formData.append(`files[${index}]`, file);
      // });

      const response = await axios.post(
        "https://api.layupdeveloper.com/playgroundqe/v1/add-comment",
        {
          discussion_id : discussion.id,
         description : newComment
        },
        {
          headers: {
            Authorization:
            `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      const result = response.data;
      console.log(result);
      if (result && result.commentData) {
        const { commentData } = result;
        if (
          commentData.commentCount !== undefined &&
          commentData.comment !== undefined
        ) {
          setDiscussionComments((prevState) => ({
            commentsCount: commentData.commentCount,
            commentList: [...prevState.commentList, commentData.comment],
          }));
          setNewComment("");
          setError("");
          setFiles([]);
        } else {
          setError("Comment data is incomplete.");
        }
      } else {
        setError("Comment data is missing in the response.");
      }
    } catch (error) {
      setError("Failed to add comment.");
      console.error("Add comment error:", error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleAddComment();
      setNewComment("");
    }
  };

  const formatTime = (time) => {
    let formattedTime;
    try {
      formattedTime = moment(time, "M/D/YYYY, h:mm:ss A").fromNow();
    } catch (error) {
      console.error("Invalid time value:", time);
      return "Invalid time";
    }
    return formattedTime || "Invalid time";
  };

  return (
    <div className="comment-section">
      {discussionComments.commentList.length > 2 && (
        <h3 className="prevCommentsHeader">View previous comments</h3>
      )}

      <div className="comments-list">
        {discussionComments.commentList.length > 0 ? (
          discussionComments.commentList.map((comment) => (
            <div className="comment" key={comment.id}>
              <ProfileImage
                imageUrl={`${BASE_IMAGE_URL}${comment.userId}/${comment.userImage}`}
                name={comment.userName}
              />
              <div className="comment-content">
                <div className="comment-user-name">{comment.userName}</div>
                <div className="comment-text">{comment.comment}</div>
                {comment.attachments &&
                  comment.attachments.media.length > 0 && (
                    <img
                      src={commentGif}
                      alt="Attachment"
                      className="comment-attachment"
                    />
                  )}
                <div className="comment-time">
                  {formatTime(new Date(comment.createdAt).toLocaleString())}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p></p>
        )}
      </div>

      <div className="new-comment">
        <div className="comment-input-wrapper">
          <img   src={`${BASE_IMAGE_URL}${userId}/${userImage}`} alt="User" className="user-pic" />
       
          <textarea
            placeholder="Write a comment..."
            className="comment-input"
            id="AddComment"
            value={newComment}
            onChange={handleCommentChange}
            onKeyDown={handleKeyDown}
          >
            {fileNames.length > 0 && `Files: ${fileNames.join(', ')}`}
          </textarea>
          <div className="comment-actions">
            <div className="emoji-picker">
              <button>
                <i className="far fa-smile"></i>
              </button>
            </div>
            <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="file-input"
            id="file-input"
            style={{ display: 'none' }}
          />
            <button className="add-attachments"  onClick={handleFileInputClick}>Add Attachments</button>
            {/* <button onClick={handleAddComment}>Add Comment</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentSection;
