import React, { useState } from 'react';
import '../addDiscussion/addDiscussion.css'; 
import axios from 'axios'; 

const AddDiscussion = ({ onClose, token }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState('');
  const [material, setMaterial] = useState(''); 
  const [file, setFile] = useState(null); 

  const handleOptionChange = (e) => {
    setSelectedOption(Number(e.target.value));
  };

  const handleMaterialChange = (e) => {
    setMaterial(e.target.value);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

   
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    // formData.append('tags', tags);
    // formData.append('learningMaterials', material);
    formData.append('accessibility', selectedOption);
    // formData.append('userAssign',userAssign);
    // formData.append('user_id',user_id);
    // formData.append('fileUploads', fileUploads);
    if (file) {
      formData.append('file', file);
    }

    try {
     console.log(formData);
      const response = await axios.post(
        'https://api.layupdeveloper.com/playgroundqe/v1/adding-a-discussion',
         
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data', 
            Authorization:  `Bearer ${token}`,
          },
        }
      );
      console.log('Discussion added:', response.data);
      onClose(); 
    } catch (error) {
      console.error('Error adding discussion:', error);
    }
  };

  return (
    <div className="add-discussion">
      <h3 className="discussion-title">New Discussion</h3>
      <hr className="divider" />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="title">Title:</label>
          <input
            type="text"
            id="title"
            placeholder="Enter discussion title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label className="description">Description:</label>
          <input
            type="text"
            id="description"
            placeholder="Enter description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label className="material">Add Learning Material:</label>
          <select id="material" value={material} onChange={handleMaterialChange}>
            <option value="">Select an option</option>
            <option value="material1">Option 1</option>
            <option value="material2">Option 2</option>
          </select>
        </div>
        <div className="form-group">
          <label className="tags">Tags:</label>
          <input
            type="text"
            id="tags"
            placeholder="Enter tags separated by commas"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Accessibility:</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="accessibility"
                value= "1"
                checked={selectedOption === 1}
                onChange={handleOptionChange}
              />
              Public Discussion
            </label>
            <label>
              <input
                type="radio"
                name="accessibility"
                value="0"
                checked={selectedOption === 0}
                onChange={handleOptionChange}
              />
              Private Discussion
            </label>
          </div>
        </div>
        <hr className="divider" />
        <div className='buttons-group'>
          <div className="form-group">
            <input
              type="file"
              id="file"
              className='input-file'
              onChange={handleFileChange}
            />
          </div>
          <div className="form-actions">
            <button type="button" onClick={onClose} className="cancel-button">Cancel</button>
            <button type="submit" className="submit-button">Share Discussion</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddDiscussion;
