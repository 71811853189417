import React from 'react';
import profileImage from '../../images/profile.jpg';
import { faCalendar, faComment, faLightbulb, faBell, faCog, faLifeRing, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../topNav/topNav.css'


function TopNav({onAddDiscussionClick,isAddDiscussionOpen }) {
 
  return (
    <div className="top-nav">
      <h2>Discussions</h2>
      {!isAddDiscussionOpen && (
        <button className='addButton' onClick={onAddDiscussionClick}>
          Add new discussion
        </button>
      )}
    </div>
  );
}

export default  TopNav;