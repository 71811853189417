import React, { useEffect,useState } from 'react';
import SideNav from './components/drawer/SideNav';
import './App.css';
import Discussion from './views/Discussion/DIiscussion';
import TopNav from './components/topNav/topNav';
import IdeaBox from './views/ideabox/Ideabox';
import {BrowserRouter as Router, Route, Routes, useLocation  } from 'react-router-dom';
import axios from 'axios';

const App = () => {
  const [token, setToken] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();

  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const loginParam = params.get('atk');

    let getAtk = sessionStorage.getItem('atk')
    console.log('params', params, 'loginParam', loginParam, 'getAtk', getAtk)

    if(getAtk){
      if(loginParam !== getAtk){
        getLoginApi(loginParam)
        sessionStorage.setItem('atk', loginParam)
        console.log('inside session with atk if')
      }
      else{
        console.log('inside session with atk else')
        setIsLoggedIn(true); 
        const tokenNew = sessionStorage.getItem('authToken');
        const userId = sessionStorage.getItem('userId');
        const userImage = sessionStorage.getItem('userImage');
        const userName = sessionStorage.getItem('userName');
        setUserId(userId);
        setUserImage(userImage);
        setToken(tokenNew);
        setUserName(userName);
      }
      console.log('inside if')
    }
    else{
      console.log('inside else')
      sessionStorage.setItem('atk', loginParam)
      getLoginApi(loginParam)
    }

  }, []);

  const getLoginApi = (loginParam) => {
      console.log('getLoginApi')

      // Call your login API if the URL parameter is present or has changed
      axios.post('https://api.layupdeveloper.com/playgroundqe/v1/login', 
        { 
          random_token: loginParam
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        }
      )
        .then(response => {
          // Handle successful login
          console.log('Login successful,  token :', response.data.result.result.authorisation.token);
          const authToken = response.data.result.result.authorisation.token;
          const userImage = response.data.result.result.user.image;
          const userId = response.data.result.result.user.id;
          const userName = response.data.result.result.user.name;
          console.log(userId);
          console.log(userImage);
          setUserImage(userImage);
          setUserId(userId);
          setIsLoggedIn(true); 
          sessionStorage.setItem('authToken', authToken);
          sessionStorage.setItem('userId', userId);
          sessionStorage.setItem('userImage', userImage);
          sessionStorage.setItem('userName', userName);
          setToken(authToken);
        })
        .catch(error => {
          // Handle errors
          console.error('Login failed:', error);
          setIsLoggedIn(false);
        });
  }

  


  return (
      <Routes>
        {/* <Route path="/" element={<Discussion />} /> */}
        <Route path="/" element={isLoggedIn ? <Discussion token={token} userImage={userImage} userId={userId} userName={userName} /> : <div>Loading...</div>} />
        <Route path="/sidenav" element={isLoggedIn ? <SideNav  token={token}  userImage={userImage} userId={userId} userName={userName} /> : <div>Loading...</div>} />
        <Route path="/ideabox" element={<IdeaBox />} />
        <Route path="/topnav" element={<TopNav />} />
      </Routes>
  );
};

export default App;
