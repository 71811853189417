import React, { useState, useEffect } from "react";
import "./Discussion.css";
import SideNav from "../../components/drawer/SideNav";
import TopNav from "../../components/topNav/topNav";
import SingleDiscussion from "../../components/singleDiscussion/singleDiscussion";
import DiscussionItem from "../../components/discussionItem/discussionItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "../../components/dicussionDropdown/dropdown";
import NoDiscussions from "../../components/defaultDiscussion/defaultDiscussion";
import AddDiscussion from "../../components/addDiscussion/addDiscussion";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";

const BASE_IMAGE_URL =
  "https://assets.layupcloud.com/uploads/playgroundqe/profile/";

const Discussion = ({token, userImage,userId, userName}) => {
  const [discussionList, setDiscussionList] = useState([]);
  const [selectedDiscussion, setSelectedDiscussion] = useState(null);
  const [showAddDiscussion, setShowAddDiscussion] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState(1);
  const [showSearchBar, setShowSearchBar] = useState(false);
  //new updates
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDiscussions, setFilteredDiscussions] = useState([]);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `https://api.layupdeveloper.com/playgroundqe/v1/discussions?type=${filterType}&page=${page}`,
        {
          headers: {
            Authorization:
              `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );
      const newItems = res.data.result.discussionList;

      if (newItems.length === 0) {
        setHasMore(false);
      } else {
        setDiscussionList((prevItems) => [...prevItems, ...newItems]);

        setFilteredDiscussions((prevItems) => [...prevItems, ...newItems]);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, filterType]);

  useEffect(() => {
    const filtered = discussionList.filter((discussion) =>
      discussion.titleBrief.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDiscussions(filtered);
  }, [searchTerm, discussionList]);

  

  const handleDiscussionClick = (discussionId) => {
    const discussion = discussionList.find((d) => d.id === discussionId);
    if (!showAddDiscussion && discussion) {
      setSelectedDiscussion(discussion);
    }
  };

  const handleAddDiscussionClick = () => {
    setShowAddDiscussion(true);
  };

  const handleCloseAddDiscussion = () => {
    setShowAddDiscussion(false);
    setSelectedDiscussion(null);
  };

  const handleFilterChange = (type) => {
    setFilterType(type);
    setPage(1);
    setDiscussionList([]);
    //new search updates
    setFilteredDiscussions([]);
  };

  const toggleSearchBar = () => {
    setShowSearchBar(!showSearchBar);
  };

  return (
    <div className="app main-container">
      <TopNav
        onAddDiscussionClick={handleAddDiscussionClick}
        isAddDiscussionOpen={showAddDiscussion}
      />
      <SideNav  userImage={userImage} userId={userId} userName={userName} />
      {!showAddDiscussion && (
        <div   className="all-discussions">
          <div className="search-bar-container">
            <Dropdown onFilterChange={handleFilterChange} />
            <FontAwesomeIcon icon={faSearch} className="search-icon"  
             onClick={toggleSearchBar} 
            />
           {showSearchBar && (
              <input
                type="text"
                placeholder="Search discussions..."
                className={`search-bar ${showSearchBar ? 'expanded' : ''}`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            )}
          </div>
         
          <hr className="horizontal-line" />
          <div id="all-discussions-container" className="all-discussions-container">
          <InfiniteScroll
            dataLength={filteredDiscussions.length}
            // dataLength={discussionList.length}
            next={() => {
              if (!loading && hasMore) {
                setPage((prevPage) => prevPage + 1);
              }
            }}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
             scrollableTarget="all-discussions-container"
            // endMessage={<p>No more discussions to load</p>}
          >
            <ul>
              {/* {discussionList.map((discussion) => ( */}
              {filteredDiscussions.map((discussion) => (
                <DiscussionItem
                  key={discussion.id}
                  user_image={`${BASE_IMAGE_URL}${discussion.userId}/${discussion.userImg}`}
                  name={discussion.userName}
                  time={new Date(discussion.createdAt * 1000).toLocaleString()}
                  comments={discussion.commentCount}
                  topic={discussion.titleBrief}
                  onClick={() => handleDiscussionClick(discussion.id)}
                />
              ))}
            </ul>
          </InfiniteScroll>
          </div>
        </div>
      )}
      {showAddDiscussion && (
        <AddDiscussion onClose={handleCloseAddDiscussion} token={token}/>
      )}
      {!showAddDiscussion && selectedDiscussion && (
        <SingleDiscussion discussion={selectedDiscussion} token={token} userImage={userImage} userId={userId}/>
      )}
      {!showAddDiscussion && !selectedDiscussion && (
        <NoDiscussions
          onAddDiscussionClick={handleAddDiscussionClick}
          isAddDiscussionOpen={showAddDiscussion}
        />
      )}
    </div>
  );
};

export default Discussion;
