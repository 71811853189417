import React, { useState, useEffect } from "react";
import profileImage from "../../images/profile.jpg";
import {
  faThumbsUp,
  faCommentAlt,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./singleDiscussion.css";
import CommentSection from "../comments/comments";
import axios from "axios";
import Avatar from "react-avatar";
import moment from "moment";
// import ProfileImage from "../avatarImage/avatarImage";
import ProfileImage from "../profilePicture/profilePicture";

const BASE_IMAGE_URL =
  "https://assets.layupcloud.com/uploads/playgroundqe/profile/";

function SingleDiscussion({ discussion , token,userImage, userId}) {
  const [discussionData, setDiscussionData] = useState(discussion);
  const [discussionCommentData, setDiscussionCommentsCount] =
    useState(discussion);
  const [discussionReactions, setDiscussionReactions] = useState(discussion);
  const [discussionReactionsUser, setDiscussionReactionUser] =
    useState(discussion);

  useEffect(() => {
    const fetchDiscussionDetails = async () => {
      try {
        const res = await axios.get(
          `https://api.layupdeveloper.com/playgroundqe/v1/single-discussion?discussion_id=${discussion.id}&reactions=1`,
          {
            headers: {
              Authorization:
               `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        setDiscussionData(res.data.result.discussion.discussionData);
        setDiscussionReactions(res.data.result.discussion.reaction);
        setDiscussionReactionUser(
          res.data.result.discussion.reaction.userList[0]
        );
        console.log(res.data.result);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    const fetchDiscussionCommentsCount = async () => {
      try {
        const response = await axios.get(
          `https://api.layupdeveloper.com/playgroundqe/v1/single-discussion-comments?discussion_id=${discussion.id}`,
          {
            headers: {
              Authorization:
              `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        setDiscussionCommentsCount(response.data.result.comments);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchDiscussionCommentsCount();
    fetchDiscussionDetails();
  }, [discussion.id]);

  const isImageUrl = (url) => {
    const imageExtensions = /\.(jpg|jpeg|png|gif|bmp|webp)$/i;
    return imageExtensions.test(url);
  };

  const formatTime = (time) => {
    let formattedTime;
    try {
      formattedTime = moment(time, "M/D/YYYY, h:mm:ss A").fromNow();
    } catch (error) {
      console.error("Invalid time value:", time);
      return "Invalid time";
    }
    return formattedTime || "Invalid time";
  };

  const userImageUrl = `${BASE_IMAGE_URL}${discussion.userId}/${discussionData.userImg}`;

  return (
    <div className="single-discussion-container">
      <div className="profile-details">
        {/* <img src={`${BASE_IMAGE_URL}${discussion.userId}/${discussion.userImg}`} alt="Profile" className="profile-image" /> */}

        <ProfileImage imageUrl={userImageUrl} name={discussionData.userName} />

        {/* {userImageUrl && isImageUrl(userImageUrl) ? (
                    <img
                        className="profile-image"
                        src={userImageUrl}  
                      
                    />
                ) : (
                    <ProfileImage name={discussionData.userName} />
                )} */}

        <h4 className="singleUserName">{discussionData.userName}</h4>
        <h4 className="singleUserName">{discussionData.id}</h4>
      </div>
      <div className="discussion-content">
        <div className="discussion-header">
          <p className="time-ago">
            {formatTime(
              new Date(discussionData.createdAt * 1000).toLocaleString()
            )}
          </p>
        </div>
        <h3 className="discussion-title">{discussionData.title}</h3>

        <p className="discussion-description">{discussionData.description}</p>
        {/* <hr className="horizontal-line-comments" /> */}
        {/* <hr className='horizontal-line-comments'/> */}
        <div className="no-comments">
          {discussionReactions.totalUsers > 1 && (
            <p className="no-comments-users">
              {discussionReactionsUser.userName} and{" "}
              {discussionReactions.totalUsers - 1} others reacted
            </p>
          )}
          {discussionReactions.totalUsers === 1 && (
            <p className="no-comments-users">
              {discussionReactionsUser.userName} reacted
            </p>
          )}

          <p className="no-comments-p">
            {discussionCommentData.commentsCount} Comments
          </p>
        </div>
        {/* <hr className="horizontal-line-comments-count" /> */}
        <div className="discussion-actions">
          <button className="action-button">
            <FontAwesomeIcon icon={faThumbsUp} /> Like
          </button>
          <button className="action-button">
            <FontAwesomeIcon icon={faCommentAlt} /> Comment
          </button>
          <button className="action-button">
            <FontAwesomeIcon icon={faShare} /> Share
          </button>
        </div>
        <hr className="horizontal-line-comments-count" />

        <CommentSection discussion={discussion} token={token} userImage={userImage} userId={userId}/>
      </div>
    </div>
  );
}

export default SingleDiscussion;
